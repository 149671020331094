<template>
  <transition name="fade-in-up">
    <RWCard>
      <template v-slot:body>
        <b-container>
          <Overview :id="id" />
          <Navigation :routes="links" />
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </b-container>
      </template>
    </RWCard>
  </transition>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import Overview from "@/views/pages/product/lightsail/manage/overview.vue";
import Navigation from "@/views/pages/product/lightsail/components/TheNavTab.vue";

export default {
  components: {
    Overview,
    RWCard,
    Navigation
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      links: [
        { name: "ls-connect", label: "Connect" },
        { name: "ls-ssh-key", label: "SSH Keys" },
        { name: "ls-metric", label: "Metric" },
        { name: "ls-firewall", label: "Firewall" },
        { name: "ls-billing", label: "My Billing" }
      ]
    };
  }
};
</script>
