<template>
  <div>
    <b-row>
      <b-col sm="3" md="2">
        <b-img center thumbnail style="border: 0px" :src="osImage"></b-img>
      </b-col>
      <b-col sm="9" md="10">
        <b-row>
          <b-col sm="12" md="12">
            <h2>AWS-0001</h2>
          </b-col>
        </b-row>
        <b-row align-v="end" align-h="between">
          <b-col sm="6" md="6">
            <p class="text-instance">512 MB RAM, 1 vCPU, 20 GB SSD</p>
            <p class="text-instance">Debian</p>
            <p class="text-instance">Singapore, Zone A (ap-southeast-1a)</p>
          </b-col>
          <b-col sm="6" md="6">
            <b-row align-h="end">
              <BaseButton
                customClass="ls-button"
                type="button"
                name="Stop"
                @click="stopInstance()"
              />
              <div class="mr-2 ml-2"></div>
              <BaseButton
                type="button"
                name="Start"
                customClass="ls-button"
                @click="startInstance()"
              />
            </b-row>
            <b-row align-h="end">
              <p
                class="text-instance"
                style="margin-right: 10px; font-size: 12pt"
              >
                Status : <strong class="bold-instance">Aktif</strong>
              </p>
            </b-row>
          </b-col>
        </b-row>

        <hr style="border-top: 1px dashed rgba(0, 0, 0, 0.1)" />

        <b-row class="text-right">
          <b-col sm="12" md="12">
            <p class="text-instance" style="font-size: 12pt">
              Static IP :
              <strong class="bold-instance">13.214.104.30</strong>
            </p>
            <p class="text-instance" style="font-size: 12pt">
              Private IP :
              <strong class="bold-instance">172.26.10.139</strong>
            </p>
            <p class="text-instance" style="font-size: 12pt">
              Public IPv6 :
              <strong class="bold-instance"
                >2406:da18:c83:9d00:ac82:e5b7:4355:a3df</strong
              >
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseButton from "@/views/pages/product/lightsail/components/BaseButton.vue";

export default {
  components: {
    BaseButton
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      osImage:
        "https://drive.google.com/uc?id=1kewHaw5W9AJbRYCTdpLuwEi4kL9fEKt5"
    };
  },
  created() {
    console.log(this.id);
  },
  methods: {
    stopInstance() {
      console.log("Stop button clicked");
    },
    startInstance() {
      console.log("Start button clicked");
    }
  }
};
</script>

<style>
.ls-button,
.ls-button:focus {
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #dd6b10;
  border-color: #dd6b10;
  color: white;
}

.ls-button:hover,
.ls-button:active,
.ls-button:visited {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: white;
  outline: none;
}

.ls-button[disabled],
.ls-button[disabled]:hover {
  background-color: #eaeded !important;
  border-color: rgba(0, 0, 0, 0.4) !important;
  outline: none;
  color: rgba(0, 0, 0, 0.4);
}

.text-instance {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bold-instance {
  font-weight: 600;
}
</style>
