<template>
  <div>
    <b-row class="mt-4">
      <v-tabs fixed-tabs>
        <v-tab
          v-for="route in routes"
          :key="route.name"
          :to="{ name: route.name }"
        >
          {{ route.label }}
        </v-tab>
      </v-tabs>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      required: true
    }
  }
};
</script>
